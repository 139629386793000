<div class="text-element" [style.font-size.px]="fontSize">

    <div class="container mt-4 color">
        <div class="row">
            <div class="col-7 mt-3">
                <div class="row" style="margin-top: -25px">
                    <!-- Show image solution -->
                    <img *ngIf="showSolution" src="{{ imgUrl }}" class="showsolution" />

                    <div id="slidingPuzzleWrapper">
                        <table id="slidingPuzzle"></table>
                    </div>
                </div>
                <div class="row justify-content-center" class="solution-image" *ngIf="isplaying">
                    <div class="p-5">
                        <!-- Dipslay Play Time  -->
                        <div class="circle">
                            <mat-spinner [mode]="spinnerMode"> </mat-spinner>
                            <p class="display_time">{{ display }}</p>
                        </div>
                    </div>

                    <div class="p-5">
                        <!-- Show image solution button -->
                        <img (mouseup)="showSolution = false" (mousedown)="showSolution = true" src="\assets\img\eye_icon.png" style="cursor: pointer" />
                    </div>
                </div>
            </div>
            <div class="col-5 mb-5">
                <div class="row">
                    <h5 [style.font-size.px]="4+fontSize">{{ "puzzle_title_1" | translate }}</h5>
                </div>
                <div class="row mb-4">
                    <p>{{ "puzzle_title_2" | translate }}</p>
                    <p>{{ "puzzle_tittle_3" | translate }}</p>
                </div>
                <div class="row mb-5">
                    <mat-form-field appearance="fill" class="color">
                        <mat-label class="color">{{ "puzzle_option" | translate }}</mat-label>
                        <div class="select">
                            <select matNativeControl (change)="selectType($event)">
                <option *ngFor="let option of types" [value]="option.value" [selected]="selectedType === option.value">
                  {{ option.viewValue | translate }}
                </option>
              </select>
                        </div>
                    </mat-form-field>
                </div>
                <div class="carousel-styles">
                    <div class="row mb-5 justify-content-center">
                        <div style="position: relative">
                            <div class="carousel-styles">
                                <a class="arrow-prev" role="button" (click)="decrease()">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <div class="carousel-container">
                                    <img *ngFor="let image of puzzleImages; let i = index" src="{{ image.img }}" alt="Galeeriano" [ngClass]="{ 'image-active': selectedIndex === i }" />
                                </div>

                                <!-- Indicators  -->

                                <a class="arrow-next" role="button" (click)="increase()">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>

                            <!-- Play Butoon  -->

                            <div class="row justify-content-center" style="margin-top: 10px">
                                <mat-icon *ngIf="!isplaying" class="material-icons-outlined" (click)="startGame(false)">
                                    play_arrow
                                </mat-icon>
                                <mat-icon *ngIf="isplaying" class="material-icons-outlined" (click)="startGame(false)">
                                    replay
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
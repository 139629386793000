import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextSizeService } from 'src/app/services/text-size-service.service';

@Component({
  selector: 'app-three-ddialog',
  templateUrl: './three-ddialog.component.html',
  styleUrls: ['./three-ddialog.component.css'],
})
export class ThreeDdialogComponent implements OnInit {
  fontSize!: number

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private textService: TextSizeService
  ) { }

  ngOnInit(): void {
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }
}

<div [style.font-size.px]="fontSize">
  <!-- Dispaly close button of the Dialog -->
  <button class="close-button" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>

  <!-- Display additional Information about Skapani Mobile-app -->
  <div class="mb-4 ml-2" [style.font-size.px]="fontSize">
    <p>{{ "android_ios_1" | translate }}</p>
    <a href=" https://play.google.com/store/apps/details?id=com.arxnet.escapani.app" target="_blank">
      <img class="vMiddle pointer" src="/assets/img/googleplay.jpg" alt="Galeeriano" />
    </a>
    <a href=" https://apps.apple.com/us/app/e-skapani/id6446626783" target="_blank">
      <img class="vMiddle pointer" src="/assets/img/appstore.jpg" alt="Galeeriano" />
    </a>
    <p>{{ "android_ios_2" | translate }}</p>
  </div>

  <!-- Display Virutal Museum Items Images -->
  <div class="overflow-auto">
    <div class="row text-center">
      <div class="col" *ngFor="let museumItem of allMuseumItems; let i = index">
        <img class="numbers" src="{{ museumItem.categoryImage }}" alt="Galeeriano" (click)="changeData(i + 1)" />
      </div>
    </div>

    <div class="row bbdot mb-2"></div>
    <!-- Display Virutal Museum Items name -->
    <div class="row mb-2 mytestcalls">
      <div class="col text-center" *ngFor="let dataPlace of allMuseumItems; let i = index">
        <h6 [style.font-size.px]="fontSize" *ngIf="selectedItem === i + 1" [innerHtml]="selectedItemName | translate">
        </h6>
      </div>
      <!-- Display additional Information about selected Virtual Museum Item -->
      <!-- Carousel for Virtal MuseumItems  -->
      <app-carousel-for-virtualMuseumItems [details]="selectedItemData">
      </app-carousel-for-virtualMuseumItems>
    </div>

    <!-- Display Images and Links for Amth -->
    <div class="bottom-footer">
      <div class="solid"></div>

      <div class="icon-row">
        <div *ngIf="virtualwalkpage; else virtualmuseum">
          <img class="icon-location" src="/assets/img/bottombtn_location.png" alt="Galeeriano" (click)="redirect( selectedItemDataLocation )
              " />
          <img class="icon-museum" src="/assets/img/bottombtn_galleriano.png" alt="Galeeriano" (click)="
            redirect(
              'http://galeriuspalace.culture.gr/eforeia-arxaiotiton-thes/'
            )
          " />

        </div>
        <ng-template #virtualmuseum>
          <img class="icon-location" src="/assets/img/bottombtn_location.png" alt="Galeeriano"
            (click)="redirect(selectedItemDataLocation)" />
          <img class="icon-museum" style="margin-top: 8px;" src="/assets/img/archaeologicalmuseum.png" alt="Galeeriano"
            (click)="redirect('https://www.amth.gr/')" />
        </ng-template>
      </div>
    </div>
  </div>
</div>
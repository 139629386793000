<div [style.font-size.px]="fontSize">
    <div class="w-100 img-width mb-5">
        <!-- <img class="img-width mb-3" src="/assets/img/HOME_LOGO.png" alt="Galeeriano"> -->
        <div class="text-wrapper">
            <h2 [style.font-size.px]="2*fontSize">{{ "about_app_1_4" | translate }}</h2>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center mb-5">
            <img class="mt-3" src="/assets/img/footer_img.png" alt="Galeeriano" />
        </div>
        <!-- Paradoteo 1 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE1"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}1.1 -
        </span> {{"paradotea_1_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}1.2 -
        </span> {{"paradotea_1_2" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}1.3 -
        </span> {{"paradotea_1_3" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}1.4 -
        </span> {{"paradotea_1_4" |translate}}
            </p>
        </div>
        <!-- Paradoteo 2 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE2"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}2.1 -
        </span> {{"paradotea_2_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}2.2 -
        </span> {{"paradotea_2_2" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}2.3 -
        </span> {{"paradotea_2_3" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}2.4 -
        </span> {{"paradotea_2_4" |translate}}
            </p>
        </div>
        <!-- Paradoteo 3 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE3"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}3.1 -
        </span> {{"paradotea_3_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}3.2 -
        </span> {{"paradotea_3_2" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}3.3 -
        </span> {{"paradotea_3_3" |translate}}
            </p>
        </div>
        <!-- Paradoteo 4 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE4"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}4.1 -
        </span> {{"paradotea_4_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}4.2 -
        </span> {{"paradotea_4_2" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}4.3 -
        </span> {{"paradotea_4_3" |translate}}
            </p>
        </div>
        <!-- Paradoteo 5 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE5"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}5.1 -
        </span> {{"paradotea_5_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}5.2 -
        </span> {{"paradotea_5_2" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}5.3 -
        </span> {{"paradotea_5_3" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}5.4 -
        </span> {{"paradotea_5_4" |translate}}
            </p>
        </div>
        <!-- Paradoteo 6 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE6"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}6.1 -
        </span> {{"paradotea_6_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}6.2 -
        </span> {{"paradotea_6_2" |translate}}
            </p>
        </div>
        <!-- Paradoteo 7 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE7"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}7.1 -
        </span> {{"paradotea_7_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}7.2 -
        </span> {{"paradotea_7_2" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}7.3 -
        </span> {{"paradotea_7_3" |translate}}
            </p>
        </div>
        <!-- Paradoteo 8 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE8"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}8.1 -
        </span> {{"paradotea_8_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}8.2 -
        </span> {{"paradotea_8_2" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}8.3 -
        </span> {{"paradotea_8_3" |translate}}
            </p>
        </div>
        <!-- Paradoteo 9 -->
        <div class="row flex-column justify-content-center text-center mb-3">
            <h5 class="color-lightblue" [style.font-size.px]="4+fontSize">
                {{("paradotea_EE9"|translate)}}
            </h5>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}9.1 -
        </span> {{"paradotea_9_1" |translate}}
            </p>
            <p>
                <span class="font-weight-bold">
          {{("paradotea_EE1"|translate) | slice:0:1}}9.2 -
        </span> {{"paradotea_9_2" |translate}}
            </p>
        </div>
    </div>
</div>

<div class="carousel-styles" style="width: 100%;">
  <a class="arrow-prev" role="button" (click)="decrease()">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <div class="carousel-container">


    <img *ngFor="let image of imagesArray; let i = index" src="{{ image.img }}" alt="Galeeriano"
      [ngClass]="{ 'image-active': iconPosition === i }" />
    <!-- <h5 class="text-center pt-2">{{imageLabel | translate}}</h5> -->

  </div>

  <!-- Indicators  -->

  <a class="arrow-next" role="button" (click)="increase()">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<div #myplaces>
    
        <button class="button" (click)="changeCameraPosition(false)">Go inside</button>
    
    
    
    <button class="button1" (click)="changeCameraPosition(true)">Return original Position</button>
     
    
</div>

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TextSizeService } from './services/text-size-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isPrivacyPolicyRoute = false;
  public screenWidth: any = window.innerWidth;
  title = 'skapani-app';
  fontSize = 16;
  grayscale = false;
  deviceInfo: any;
  colorScheme = 'normal';

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenWidth = window.innerWidth;
  }
  constructor(
    public translate: TranslateService, private deviceService: DeviceDetectorService, private textSizeService: TextSizeService, private router: Router, private activatedRoute: ActivatedRoute
  ) {
    translate.addLangs(['en', 'gr']);
    translate.setDefaultLang('gr');
  }

  increaseText() {
    this.fontSize += 2
    this.textSizeService.updateFontSize(this.fontSize);
  }

  decreaseText() {
    this.fontSize -= 2
    this.textSizeService.updateFontSize(this.fontSize)
  }
  resetText() {
    this.grayscale = false
    this.fontSize = 16;
    this.textSizeService.updateFontSize(this.fontSize);
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      let currentRoute = this.activatedRoute.root;
      while (currentRoute.children.length > 0) {
        currentRoute = currentRoute.children[0];
      }

      this.isPrivacyPolicyRoute = currentRoute.snapshot.routeConfig?.path === 'privacy-policy';
    });
  }

}

<div class="w-100 mt-5">
  <div
    id="carouselExampleControls"
    class="carousel vert slide"
    data-ride="carousel"
    data-interval="false"
  >
    <!-- ❌ Νot dynamic solution, the museum item has Exactly 12 items so we split them and display them after    -->
    <div class="carousel-inner">
      <!-- Displaying the first 6 items of the array. -->
      <div class="carousel-item active">
        <app-museum-item-component
          [start]="0"
          [stop]="6"
        ></app-museum-item-component>
      </div>
      <!-- Display the next 6 items of array -->
      <div class="carousel-item">
        <app-museum-item-component
          [start]="6"
          [stop]="12"
        ></app-museum-item-component>
      </div>
    </div>
    <a
      *ngIf="first != 0"
      class="carousel-control-prev positioning-u w-0"
      href="#carouselExampleControls"
      role="button"
      data-slide="prev"
      (click)="decrease()"
    >
      <span
        class="arrow-up carousel-control-prev-icon bg-dark rounded-circle"
        aria-hidden="true"
      ></span>
      <span class="sr-only"></span>
    </a>
    <a
      *ngIf="first != 1"
      class="carousel-control-next positioning-d"
      href="#carouselExampleControls"
      role="button"
      data-slide="next"
      (click)="increase()"
    >
      <span
        class="arrow-down carousel-control-next-icon bg-dark rounded-circle"
        aria-hidden="true"
      ></span>
      <span class="sr-only"></span>
    </a>
  </div>
</div>

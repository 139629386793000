<div [style.font-size.px]="fontSize">
    <div class="w-100 img-width mb-5">
        <!-- <img class="img-width mb-3" src="/assets/img/HOME_LOGO.png" alt="Galeeriano"> -->
        <div class="text-wrapper">
            <h2 [style.font-size.px]="2*fontSize">{{ "about_app_1_1" | translate }}</h2>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center mb-5">
            <img class="mt-3" src="/assets/img/footer_img.png" alt="Galeeriano" />
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h2 class="color-lightblue" [style.font-size.px]="2*fontSize">
                1. Εθνικό Κέντρο Έρευνας και Τεχνολογικής Ανάπτυξης/ Ινστιτούτο Τεχνολογιών Πληροφορικής και Επικοινωνιών/Εργαστήριο Γνώσης, Πολυμέσων και Κοινωνικών Δικτύων
            </h2>
            <p>
                Το Εθνικό Κέντρο Έρευνας και Τεχνολογικής Ανάπτυξης (ΕΚΕΤΑ) ιδρύθηκε το 2000, είναι ένα από τα κορυφαία ερευνητικά κέντρα της Ελλάδας και βρίσκεται μέσα στη λίστα με τους TOP-25 ερευνητικούς και ακαδημαϊκούς φορείς της Ε.Ε. στην προσέλκυση πόρων από ανταγωνιστικά
                ευρωπαϊκά προγράμματα. Νομική Μορφή Το ΕΚΕΤΑ είναι Νομικό Πρόσωπο Ιδιωτικού Δικαίου (ΝΠΙΔ) μη κερδοσκοπικού χαρακτήρα που εποπτεύεται από τη Γενική Γραμματεία Έρευνας και Τεχνολογίας (ΓΓΕΤ) του Υπουργείου Παιδείας, Έρευνας και Θρησκευμάτων.
                Ερευνητικές Περιοχές Το ΕΚΕΤΑ έχει να επιδείξει σημαντικά επιστημονικά και τεχνολογικά επιτεύγματα σε ερευνητικές περιοχές με μεγάλο ενδιαφέρον για τον άνθρωπο και την κοινωνία όπως: Ενέργεια, Περιβάλλον, Νέα Λειτουργικά Υλικά, Βιομηχανικές
                Διεργασίες, Πληροφορική, Τηλεματική, Τηλεπικοινωνίες, Μεταφορές, Αγροβιοτεχνολογία, Επιστήμες Υγείας, Μηχανοτρονική, Αγροτεχνολογία, καθώς επίσης και σε διάφορες διαθεματικές επιστημονικές και τεχνολογικές περιοχές που προκύπτουν από τα
                παραπάνω. Ινστιτούτα Στη σημερινή του μορφή το Κέντρο περιλαμβάνει τα ακόλουθα πέντε (5) ινστιτούτα: Ινστιτούτο Χημικών Διεργασιών και Ενεργειακών Πόρων (ΙΔΕΠ): Βιώσιμη και Καθαρή Ενέργεια, Περιβαλλοντικές Τεχνολογίες,Χημικές και Βιοχημικές
                Διαδικασίες, Προηγμένα Λειτουργικά Υλικά Ινστιτούτο Τεχνολογιών Πληροφορικής και Επικοινωνιών (ΙΠΤΗΛ): Πληροφορική, Τηλεματική και Τηλεπικοινωνίες Ινστιτούτο Βιώσιμης Κινητικότητας και Δικτύων Μεταφορών (ΙΜΕΤ): Βιώσιμη Κινητικότητα και
                Συνδυασμένες Μεταφορές Ινστιτούτο Εφαρμοσμένων Βιοεπιστημών (ΙΝΕΒ): Αγροβιοτεχνολογία, Μεταφραστική Ιατρική Έρευνα, Πληροφορική Βιοδεδομένων Μεγάλης Κλίμακας Ινστιτούτο Έρευνας και Τεχνολογίας Θεσσαλίας (ΙΕΤΕΘ): Μηχανοτρονική, Αγροτεχνολογία,
                Βιοιατρική, Κινησιολογία Δραστηριότητα Στο ΕΚΕΤΑ σήμερα εργάζονται περισσότερα από 700 άτομα, στην πλειοψηφία τους επιστήμονες. Το ΕΚΕΤΑ έχει λάβει πολλά βραβεία και σημαντικές διακρίσεις όπως το European Descartes Prize, European Research
                Council (ERC) Advanced Grant, Microsoft International Contest Prize, the Trading Agents Competition Award και πολλά άλλα, ενώ κατατάσσεται στα 20 πρώτα Ευρωπαϊκά Ερευνητικά Κέντρα μεnβάση την υψηλή συμμετοχή του στα χρηματοδοτούμενα προγράμματα
                του Η2020. Το ΕΚΕΤΑ έχει συμμετάσχει σε περισσότερα από 1.200 ερευνητικά έργα (με συνολικό προϋπολογισμό που ξεπερνάει τα 450 Μ€ και μεnπερισσότερες από 1.100 διεθνείς συνεργασίες), χρηματοδοτούμενα από την Ευρωπαϊκή Ένωση, μεγάλες βιομηχανίες
                του εξωτερικού (Ευρώπη, Ασία και Αμερική) και το Ελληνικό δημόσιο μέσω της Γενικής Γραμματείας Έρευνας και Τεχνολογίας. Τα ερευνητικά αποτελέσματα του ΕΚΕΤΑ (περισσότερα από 350 άρθρα/έτος) έχουν σημαντική διεθνή επιστημονική απήχηση (περισσότερες
                από 7.100 ετεροαναφορές/έτος). Οι ερευνητικές δραστηριότητες του Κέντρου έχουν οδηγήσει στη δημιουργία 4 εταιρειών τεχνοβλαστών – spin offs. Το ΕΚΕΤΑ είναι ο πρώτος ελληνικός ερευνητικός φορέας που αναγνωρίζεται από την Ε.Ε. (2012) ότι
                σέβεται τις αρχές της “Χάρτας του Ερευνητή κ.α.
            </p>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h2 class="color-lightblue" [style.font-size.px]="2*fontSize">2. Διεθνές Πανεπιστήμιο της Ελλάδος
            </h2>
            <p>
                Το Διεθνές Πανεπιστήμιο της Ελλάδος ιδρύθηκε τον Οκτώβριο του 2005 με έδρα τη Θεσσαλονίκη. Βρίσκεται σε μια πανεπιστημιούπολη 16.000 τ.μ. έξω από τη Θεσσαλονίκη, ειδικά σχεδιασμένη για να διευκολύνει τις σύγχρονες μεθόδους μάθησης. Το Διεθνές είναι το
                πρώτο δημόσιο πανεπιστήμιο στην Ελλάδα στο οποίο τα προγράμματα σπουδών διδάσκονται αποκλειστικά στα αγγλικά. Στο Διεθνές Πανεπιστήμιο της Ελλάδος λειτουργούν τρεις (3) σχολές, η Σχολή Οικονομίας, Διοίκησης και Νομικών Επιστημών, η Σχολή
                Ανθρωπιστικών Επιστημών και η Σχολή Επιστημών Τεχνολογίας. Βασικοί άξονες λειτουργίας του Πανεπιστημίου είναι η ακαδημαϊκή αριστεία στη διδασκαλία και στην έρευνα, η εξωστρέφεια και η σύνδεση με την αγορά εργασίας. Εφαρμόζει διεθνή πρότυπα
                στην αξιολόγηση της διδασκαλίας, της έρευνας και της εν γενεί λειτουργίας του και προσελκύει κορυφαίους πανεπιστημιακούς και φοιτητές από την Ευρώπη και όλο τον κόσμο. Αξίζει να αναφερθεί ότι στην εξωτερική αξιολόγηση όλων των Α.Ε.Ι. της
                χώρας (22 Πανεπιστημίων και 14 ΤΕΙ) που πραγματοποιήθηκε από την Αρχή Διασφάλισης και Πιστοποίησης της Ποιότητας στην Ανώτατη Εκπαίδευση (ΑΔΙΠ) κατά το διάστημα Οκτώβριος 2015 – Ιούλιος 2016, το Διεθνές Πανεπιστήμιο της Ελλάδος ήταν ένα
                από τα μόλις 7 ιδρύματα της χώρας που αξιολογήθηκαν ως «Άξιο θετικής μνείας». H διεπιστημονική προσέγγιση της μάθησης αντικατοπτρίζεται στο αναλυτικό πρόγραμμα των 22 μεταπτυχιακών προγραμμάτων που προσφέρονται σήμερα από το πανεπιστήμιο.
            </p>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h2 class="color-lightblue" [style.font-size.px]="2*fontSize">3. Αρχαιολογικό Μουσείο Θεσσαλονίκης
            </h2>
            <p>
                Το Αρχαιολογικό Μουσείο Θεσσαλονίκης έχει έδρα τη Θεσσαλονίκη και είναι από τα μεγαλύτερα μουσεία της χώρας και το κεντρικό μουσείο της Βόρειας Ελλάδας. Στεγάζεται σε κτήριο που κατασκευάστηκε το 1962 σε σχέδια του αρχιτέκτονα Πάτροκλου Καραντινού, αποτελεί
                αντιπροσωπευτικό δείγμα του ελληνικού μοντερνισμού και είναι κηρυγμένο ως διατηρητέο μνημείο της νεότερης κληρονομιάς. Στόχοι του Μουσείου μεταξύ άλλων είναι η φύλαξη, συντήρηση, καταγραφή, τεκμηρίωση, έρευνα, μελέτη, δημοσίευση και κυρίως
                η έκθεση και προβολή στο κοινό των φυλασσόμενων σε αυτό Πολιτιστικών Αγαθών, ο εμπλουτισμός των συλλογών του, η διοργάνωση εκπαιδευτικών προγραμμάτων και πάσης φύσεως εκδόσεων.
            </p>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h2 class="color-lightblue" [style.font-size.px]="2*fontSize">4. Εφορεία Αρχαιοτήτων Πόλης Θεσσαλονίκης
            </h2>
            <p>
                Η Εφορεία Αρχαιοτήτων Πόλης Θεσσαλονίκης είναι Περιφερειακή Υπηρεσία της Γενικής Διεύθυνσης Αρχαιοτήτων και Πολιτιστικής Κληρονομιάς του Υπουργείου Πολιτισμού και Αθλητισμού σε επίπεδο Διεύθυνσης, έχει έδρα τη Θεσσαλονίκη και αρμοδιότητα που εκτείνεται
                στο Πολεοδομικό Συγκρότημα Θεσσαλονίκης με έδρα το φρούριο του Επταπυργίου. Η αρμοδιότητα της Εφορείας ανάγεται σε όλα τα θέματα σχετικά με τη διατήρηση, προστασία και φύλαξη των αρχαιοτήτων, την επιστημονική έρευνα, την αποκάλυψη, τη
                συντήρηση, την ανάδειξη αρχαιοτήτων και νεότερων θρησκευτικών μνημείων, και την έκθεσή τους στα Μουσεία. Επιπλέον στην αρμοδιότητά της ανήκει ο προγραμματισμός, η διαχείριση, η μελέτη και η εκτέλεση οποιουδήποτε αρχαιολογικού έργου, η
                συντήρηση, επισκευή, αποκατάσταση, αναστήλωση, ανάδειξη και διαμόρφωση των μνημείων και των αρχαιολογικών χώρων, καθώς και του φυσικού ή μη περιβάλλοντός τους, η εκτέλεση όλων των ανωτέρω έργων με αυτεπιστασία και απολογιστικά, η επιστημονική
                μελέτη και δημοσίευση των αρχαιοτήτων, η διαχείριση των μνημείων, των αρχαιολογικών χώρων, των Μουσείων και των Συλλογών, στον χώρο ευθύνης της, που ανήκουν στο κράτος, καθώς επίσης και η προετοιμασία και η εισήγηση όλων των αρχαιολογικών
                θεμάτων στα αρμόδια Τοπικά Συμβούλια Μνημείων και η εν γένει μέριμνα εφαρμογής της ισχύουσας νομοθεσίας περί προστασίας των αρχαιοτήτων.
            </p>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h2 class="color-lightblue" [style.font-size.px]="2*fontSize">5. Arx.Net</h2>
            <p>
                Η ARX.NET ΑΕ, από το 2001, προσφέρει ολοκληρωμένες λύσεις λογισμικού και αναπτύσσει καινοτόμες εφαρμογές και υπηρεσίες προστιθέμενης αξίας σε συνεργασία με τις μεγαλύτερες εταιρείες Τηλεπικοινωνιών, Κατασκευαστών Κινητών Συσκευών και άλλες μεγάλες επιχειρήσεις
                παγκοσμίως. Οι στρατηγικές συνεργασίες με τις εταιρίες OTE/Cosmote (T-MobileGroup), Vodafone Group, Elisa Φινλανδίας, Huawei, Samsung, Telefonica/Movistar, Wind, Cyta και άλλες εταιρείες παγκοσμίως, οδήγησαν την ARX.NET στο επιτυχημένο
                λανσάρισμα μιας σειράς cloud-based υπηρεσιών, mobile εφαρμογών/υπηρεσιών, mobile portals, υπηρεσιών billing και πλατφορμών παράδοσης περιεχομένου. Σήμερα, η ARX.NET, συγκαταλέγεται στους παγκόσμιους ηγέτες των cloud-based υπηρεσιών προστιθέμενης
                αξίας επόμενης γενιάς και των cross-platform εφαρμογών για Παρόχους Τηλεπικοινωνιακών Υπηρεσιών. Έχοντας επενδύσει περισσότερα από 4 χρόνια έρευνας και ανάπτυξης πάνω σε cloud-based υπηρεσίες επόμενης γενιάς, η ARX.NET προσφέρει σε Παρόχους
                Κινητής Τηλεφωνίας white label λύσεις που τους βοηθούν να εμπλουτίσουν τις προϊοντικές τους προτάσεις, να διαφοροποιηθούν από τον ανταγωνισμό, να αυξήσουν την εμπιστοσύνη των συνδρομητών τους και να αποκτήσουν νέες ροές εσόδων. Η ARX.NET
                έχει αναγνωριστεί πανευρωπαϊκά για την σημαντική εμπειρία της στην ανάπτυξη λογισμικού, τις συνεργασίες της και το λανσάρισμα υπηρεσιών στους ακόλουθους τομείς:
            </p>
            <ul class="mb-4">
                <li>
                    Cloud-based υπηρεσίες επόμενης γενιάς για καταναλωτές και επιχειρήσεις (white label λύσεις για Παρόχους Τηλεπικοινωνιακών Υπηρεσιών και Κατασκευαστές Κινητών)
                </li>
                <li>
                    Μobile, SmartTV, PC, MAC και WebTV εφαρμογές για Παρόχους Υπηρεσιών Τηλεόρασης
                </li>
                <li>
                    Mobile και SmartTV εφαρμογές για Παρόχους Τηλεπικοινωνιακών Υπηρεσιών και εταιρείες (έχει αναπτύξει πάνω από 500 εφαρμογές)
                </li>
                <li>
                    Loyalty Schemes για Παρόχους Τηλεπικοινωνιακών Υπηρεσιών και Retailers
                </li>
                <li>
                    locpush, Υπηρεσία cross-channel Mobile Marketing, Rich Push Notification και Analytics
                </li>
                <li>Υπηρεσίες SMS (premium rate SMS)</li>
                <li>
                    Ανάπτυξη και διαχείριση υπηρεσιών mobile portal για Παρόχους Κινητής Τηλεφωνίας
                </li>
                <li>
                    Πλατφόρμες χρεώσεων (Charge to bill / Mobile Billing) για τρίτους παρόχους υπηρεσιών με διασύνδεση σε Παρόχους Κινητής Τηλεφωνίας σε όλο τον κόσμο
                </li>
                <li>Πλατφόρμες διαχείρισης και συσσωμάτωσης mobile περιεχομένου</li>
            </ul>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TextSizeService } from 'src/app/services/text-size-service.service';

@Component({
  selector: 'app-plaisio',
  templateUrl: './plaisio.component.html',
  styleUrls: ['./plaisio.component.css']
})
export class PlaisioComponent implements OnInit {
  fontSize!: number

  constructor(private textTransform: TextSizeService) { }

  ngOnInit(): void {
    this.textTransform.currentFontSize.subscribe(size => this.fontSize = size)
  }

}

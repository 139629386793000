<div class="position-relative w-100 h-100vh">
  <div class="position-absolute h-100 w-100">
    <img
      class="position-absolute w-100 h-100"
      src="/assets/img/bg.jpg"
      alt="Galeeriano"
    />
    <div
      class="position-absolute w-100 h-100 opacity-07 light-black-color top-0"
    ></div>

    <app-carusel></app-carusel>
  </div>
</div>

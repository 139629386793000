<div [style.font-size.px]="fontSize">
    <div class="w-100 img-width mb-5">
        <!-- <img class="img-width mb-3" src="/assets/img/HOME_LOGO.png" alt="Galeeriano"> -->
        <div class="text-wrapper">
            <h2>{{ "news" | translate }}</h2>
        </div>
    </div>
    <div class="container" *ngIf="newsData?.title">
        <div class="row justify-content-center mb-5">
            <img class="mt-3" src="/assets/img/footer_img.png" alt="Galeeriano" />
        </div>
        <div class="row justify-content-center text-center mb-3">
            <!--Title -->
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue">
                {{newsData?.title|translate}}
            </h3>.
            <!-- img-First -->
            <div class="pt-3">
                <img class="main-image" [src]="newsData?.categoryData[0].img">
                <!-- imageLabel -->
                <p class="text-center pt-1 thumbnail-caption">{{newsData?.categoryData[0]?.label|translate}}</p>
                <!-- image date created -->
                <div class="text-left time d-flex ">
                    <mat-icon class="icon-display">access_time</mat-icon>
                    <p class="pl-2 pt-1">{{newsData?.date|translate}}</p>
                </div>
            </div>
            <p class="text-left  text">
                {{newsData?.text|translate}}
            </p>


            <!-- Carousel Image if  -->
            <ng-container *ngIf="newsData?.categoryData.length>1">

                <div id="carouselExampleIndicators" class="carousel slide w-75" data-ride="carousel" data-interval="false">
                    <!-- Display selected VirtualMuseum/VirtualWalk Item Image -->
                    <div class="carousel-inner pt-2">
                        <div class="carousel-item" *ngFor="let detail of newsData?.categoryData; let i = index; let isFirst=first" [ngClass]="{ active: isFirst  }" data-slide="i">
                            <div class="image">
                                <img src="{{ detail.img }}" alt="Galeeriano" class="carousel-test" (click)="zoomImage(i)" />
                            </div>
                        </div>
                    </div>
                    <!-- Display Carousel Indicators if  there is more than 1 image-->

                    <a class="carousel-control-prev center-arrow" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next center-arrow" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </ng-container>
            <!-- Media Player -->

            <div *ngIf="newsData?.videos" class="w-100 pt-4 d-flex justify-content-between">

                <video width="560" height="315" controls>
          <source src="share/skapani-3dmodels/videos/OpenLabsVideo_01.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
                <video width="560" height="315" controls>
          <source src="share/skapani-3dmodels/videos/OpenLabsVideo_02.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>

                <!-- </div> -->
            </div>
        </div>
    </div>
</div>
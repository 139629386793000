import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DialogData} from '../imagedialog/imagedialog.component';

@Component({
  selector: 'app-win-img',
  templateUrl: './win-img.component.html',
  styleUrls: ['./win-img.component.css']
})
export class WinImgComponent implements OnInit {

  public url: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,public dialog: MatDialog) { }

  ngOnInit(): void {

    this.url = this.data.url;
  }
}

<div>
    <div class="row text-center">
        <div class="col-4 mt-5 mb-5" style="display: flex; align-items: center; justify-content: center" *ngFor="let museumItem of allMuseumItems?.slice(start, stop); let i = index">
            <div class="test">
                <div class="images">
                    <img class="image-size" [ngClass]="('type-'+museumItem.model)" [src]="museumItem.img" (click)="openDialog(museumItem.categoryData)" />

                    <img src="\assets\img\3dicon.png" class="threeD-icon" (click)="open3DDialog(museumItem.model)" title=" {{ 'threeD_description' | translate }}" />
                </div>

                <h5 style="color: white; margin-top: 10px" [style.font-size.px]="4+fontSize">
                    {{ museumItem.desc_short | translate }}
                </h5>

                <div class="border-item-style-bottom"></div>
                <div class="border-item-style-right"></div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TextSizeService } from '../services/text-size-service.service';

@Component({
  selector: 'app-paradotea',
  templateUrl: './paradotea.component.html',
  styleUrls: ['./paradotea.component.css']
})
export class ParadoteaComponent implements OnInit {
  fontSize!: number

  constructor(private textService: TextSizeService) { }

  ngOnInit(): void {
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MuseumDetailedDialogComponent } from 'src/app/src/app/componenets/modals/museum-detailed-dialog/museum-detailed-dialog.component';

@Component({
  selector: 'app-museum-items',
  templateUrl: './museum-items.component.html',
  styleUrls: ['./museum-items.component.css'],
})
export class MuseumItemsComponent implements OnInit {
  public test: boolean = true;
  constructor() {}

  ngOnInit(): void {}
}

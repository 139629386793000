import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FbxloaderComponent } from './componenets/fbxloader/fbxloader.component';
import { PagenotfoundComponent } from './componenets/pagenotfound/pagenotfound.component';
import { MaincontainerComponent } from './containers/maincontainer/maincontainer.component';
import { VirtualwalkComponent } from './containers/virtualwalk/virtualwalk.component';
import { VirtualmuseumComponent } from './containers/virtualmuseum/virtualmuseum.component';
import { FbxdialogComponent } from './componenets/modals/fbxdialog/fbxdialog.component';
import { MuseumItemsComponent } from './containers/museum-items/museum-items.component';
import { PuzzlecontainerComponent } from './containers/puzzlecontainer/puzzlecontainer.component';
import { DomhComponent } from './componenets/domh/domh.component';
import { KinopraxiaComponent } from './componenets/kinopraxia/kinopraxia.component';
import { PlaisioComponent } from './componenets/plaisio/plaisio.component';
import { PrivacyPolicyComponent } from './componenets/privacy-policy/privacy-policy.component';
import { NewsPagesComponent } from './componenets/news-pages/news-pages.component';
import { ParadoteaComponent } from './paradotea/paradotea.component';
import { NewsPageComponent } from './news-page/news-page.component';
const routes: Routes = [
  { path: 'fbxloader', component: FbxloaderComponent },
  { path: 'virtualwalk', component: VirtualwalkComponent },
  { path: 'virtualmuseum', component: VirtualmuseumComponent },
  { path: 'museumItems', component: MuseumItemsComponent },
  { path: 'domh', component: DomhComponent },
  { path: 'news', component: NewsPagesComponent },
  { path: 'news/new', component: NewsPageComponent },
  { path: 'deliverables', component: ParadoteaComponent },
  { path: 'kinopraxia', component: KinopraxiaComponent },
  { path: 'plaisio', component: PlaisioComponent },
  { path: 'puzzle', component: PuzzlecontainerComponent },
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'main', component: MaincontainerComponent },
  { path: 'fbxdialog/:id', component: FbxdialogComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: '**', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

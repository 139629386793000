<div class="museum-wrapper">
  <img
    class="img-correction"
    src="/assets/img/MikroToxo_02.jpg"
    [ngClass]="{ 'img-afterdialog': isOpendialog }"
    alt="Galeeriano"
  />
  <img
    class="number1"
    src="/assets/img/toxo.png"
    alt="Galeeriano"
    [ngClass]="{ 'number1-afterdialog': isOpendialog }"
    (click)="selectedNumber(1)"
  />
  <img
    class="number2"
    src="/assets/img/epikrana.png"
    alt="Galeeriano"
    [ngClass]="{ 'number2-afterdialog': isOpendialog }"
    (click)="selectedNumber(2)"
  />
  <img
    class="number3"
    src="/assets/img/marmarothetimata.png"
    alt="Galeeriano"
    [ngClass]="{ 'number3-afterdialog': isOpendialog }"
    (click)="selectedNumber(3)"
  />
</div>

<div class="container-fluid justify-content-center mt-5">
  <div class="row justify-content-center mb-5">
    <img class="nav-img-logo mt-2" src="/assets/img/logo.png" alt="Galeeriano">
  </div>
  <div class="row text-center justify-content-center mb-5">
    <h1>Download Mobile apps</h1>
  </div>
  <div class="row text-center mb-5">
    <div class="col-12 ">
      <a href="https://play.google.com/store/apps/details?id=com.arxnet.escapani.app" target="_blank">
        <img class="w-50 mt-2" src="/assets/img/android-store-en.png" alt="Galeeriano">
      </a>
    </div>
  </div>
  <div class="row text-center">
    <div class="col-12">
      <a href=" https://apps.apple.com/us/app/e-skapani/id6446626783" target="_blank">
        <img class="w-50" src="/assets/img/apple-store-en.png" alt="Galeeriano">
      </a>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MessageComponent } from '../../message/message.component';
@Component({
  selector: 'app-snackbarmessage',
  templateUrl: './snackbarmessage.component.html',
  styleUrls: ['./snackbarmessage.component.css']
})
export class SnackbarmessageComponent implements OnInit {
 

  ngOnInit(): void {
  }

 

}

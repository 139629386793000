import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagedialogComponent } from '../componenets/modals/imagedialog/imagedialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TextSizeService } from '../services/text-size-service.service';
@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.css']
})
export class NewsPageComponent implements OnInit {
  fontSize!: number
  details: any
  active: any
  newsData!: any
  newsID!: number;
  iconPosition: any;
  url: any
  constructor(private textService: TextSizeService, private httpClient: HttpClient, private router: ActivatedRoute, private route: Router, public dialog: MatDialog, private sanitizer: DomSanitizer) {
    this.router.queryParams.subscribe(params => {
      if (typeof (params.newsID) != 'string') {
        this.route.navigate(['/news'])
      } else {
        this.newsID = Number(params.newsID)
      }

    });
  }
  ngOnInit(): void {
    this.fetchNews(this.newsID)
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }

  fetchNews(id: number) {
    this.httpClient.get(`assets/news/news.json`, { responseType: 'json' })
      .subscribe((data: any) => {
        this.newsData = data[id]
      })
  }




  //Zoom image of Carousel
  zoomImage(iconPosition: number) {
    let activeEle = document.querySelector('.carousel-item.active img');
    if (activeEle != null) {
      this.iconPosition = iconPosition
      this.url = activeEle.getAttribute('src');
      this.openDialog();
    }
  }

  openDialog() {
    this.dialog.open(ImagedialogComponent, {
      data: {
        url: this.url,
        details: this.newsData.categoryData,
        iconPosition: this.iconPosition
      },
      panelClass: 'image-dialog-presentation',
    });
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

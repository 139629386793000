import { Component, OnInit } from '@angular/core';
import { TextSizeService } from 'src/app/services/text-size-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-pages',
  templateUrl: './news-pages.component.html',
  styleUrls: ['./news-pages.component.css']
})
export class NewsPagesComponent implements OnInit {
  fontSize!: number

  constructor(private textService: TextSizeService, private router: Router) { }

  ngOnInit(): void {
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }


  goToNewsPage(newsID: number) {
    this.router.navigate(['/news/new'], { queryParams: { newsID } });
  }



}

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { WinImgComponent } from '../../componenets/modals/win-img/win-img.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageComponent } from '../../componenets/message/message.component';
import { TranslateService } from '@ngx-translate/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { TextSizeService } from 'src/app/services/text-size-service.service';
interface IPoint {
  row: number;
  col: number;
}

interface ITile {
  position: IPoint;
  empty: boolean;
}
interface GameType {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-puzzle',
  templateUrl: './puzzle.component.html',
  styleUrls: ['./puzzle.component.css'],
})
export class PuzzleComponent implements OnInit, AfterViewInit {
  // get puzzle Images
  @Input() public puzzleImages!: any[];
  board: any[] = [];
  private rows: number = 3;
  private cols: number = 3;
  public interval: any;
  public islost: boolean = true;
  public showSolution: boolean = false;
  public isplaying: boolean = false;
  time: number = 0;
  public display: any;
  public active: any = 0;
  selectedIndex: number = 0;

  // set Spinner Mode
  spinnerMode: ProgressSpinnerMode = 'indeterminate';

  timer?: number;
  types: GameType[] = [
    { value: 3, viewValue: 'nine_pieces' },
    { value: 4, viewValue: 'sixteen_pieces' },
  ];
  selectedType = this.types[0].value;
  public imgUrl: string = '';
  public selected = 0;
  private gameType = 3;
  fontSize!: number

  private readonly DIRECTIONS = [
    { x: 0, y: -1 }, // Down
    { x: 0, y: 1 }, // Up
    { x: -1, y: 0 }, // Left
    { x: 1, y: 0 }, // Right
  ];
  image = new Image();
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private textService: TextSizeService
  ) { }

  ngOnInit(): void {
    this.imgUrl = this.puzzleImages[0].img;
    this.startGame(true);
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    })

  }

  ngAfterViewInit(): void { }

  // Carousel Functions
  decrease(): void {
    if (this.selectedIndex == 0) {
      this.selectedIndex = this.puzzleImages.length - 1;
    } else {
      this.selectedIndex--;
    }
    this.isplaying = false;
    this.showSolution = false;
    this.imgUrl = this.puzzleImages[this.selectedIndex].img;
  }

  increase(): void {
    if (this.selectedIndex === this.puzzleImages.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
    this.isplaying = false;
    this.showSolution = false;
    this.imgUrl = this.puzzleImages[this.selectedIndex].img;
  }

  startGame(disabled: boolean): void {
    // Change Spinner Mode
    this.spinnerMode = 'indeterminate';
    const table: any = document.getElementById('slidingPuzzle');
    if (!disabled) {
      this.isplaying = true;
      this.islost = false;
      this.pauseTimer();
      this.startTimer();
    }
    if (table.rows.length != 0) {
      this.empty_table(table);
    }

    for (let i = 0; i < this.rows; i++) {
      this.board[i] = [];
      for (let j = 0; j < this.cols; j++) {
        this.board[i][j] = {
          position: {
            row: i,
            col: j,
          },
          empty: false,
        };
      }
    }
    this.board[this.rows - 1][this.cols - 1].empty = true;

    this.shuffle();

    for (let i = 0; i < this.rows; i++) {
      const tr = document.createElement('tr');
      for (let j = 0; j < this.cols; j++) {
        const td = document.createElement('td');
        td.setAttribute('id', `puzzle${this.rows * i + (j + 1)}`);
        td.addEventListener('click', this.move.bind(this, i, j));
        tr.appendChild(td);
      }
      if (table) {
        table.appendChild(tr);
      }
    }
    this.draw();
  }




  empty_table(table: any) {
    this.board = [];
    let child = table.lastElementChild;
    while (child) {
      table.removeChild(child);
      child = table.lastElementChild;
    }
  }
  openDialog(img: string) {
    this.dialog.open(WinImgComponent, {
      data: {
        url: img,
      },
      panelClass: 'image-dialog-presentation',
    });
  }
  openSnackBar(message: string, style: string, icon: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      duration: 3000,
      data: { message: message, icon: icon },
      panelClass: [style],
    });
  }
  selectType(event: any) {
    this.rows = parseInt((event.target as HTMLSelectElement).value);
    this.cols = parseInt((event.target as HTMLSelectElement).value);
  }
  startCountdown(seconds: number) {
    if (this.timer != null) {
      clearInterval(this.interval);
    }
    let counter = seconds;

    this.interval = setInterval(() => {
      this.timer = counter;
      counter--;

      if (counter < 0) {
        clearInterval(this.interval);
        this.openSnackBar('Game over', 'error', 'error_outline');
        this.islost = true;
      }
    }, 1000);
  }
  startTimer() {
    this.time = 0;
    this.display = 0;
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.convert(this.time.toString());
    }, 1000);
  }
  // Convert Seconds to Minutes
  convert(value: any) {
    return Math.floor(value / 60) + ':' + (value % 60 ? value % 60 : '00');
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (value - minutes * 60).toString();
  }
  pauseTimer() {
    clearInterval(this.interval);
  }

  move(row: number, col: number): void {
    if (!this.islost) {
      function change(
        this: any,
        x: number,
        y: number,
        row: number,
        col: number
      ): void {
        let temp = this.board[row][col];
        this.board[row][col] = this.board[row + y][col + x];
        this.board[row + y][col + x] = temp;
      }

      this.DIRECTIONS.forEach((direction) => {
        if (
          this.board[row + direction.y] &&
          this.board[row + direction.y][col + direction.x] &&
          this.board[row + direction.y][col + direction.x].empty
        ) {
          change.call(this, direction.x, direction.y, row, col);
        }
      });

      if (this.isCompleted()) {
        const wrapper = document.getElementById('slidingPuzzleWrapper');
        const puzzle = document.getElementById('slidingPuzzle');
        // const img = document.createElement('img');
        // img.setAttribute('src',this.imgUrl);
        this.pauseTimer();
        // Change spinner Mode
        this.spinnerMode = 'determinate';
        //this.openDialog(this.imgUrl);
        if (this.translate.store.currentLang === undefined) {
          this.language(this.translate.store.defaultLang);
        } else {
          this.language(this.translate.store.currentLang);
        }
        this.draw();
      } else {
        this.draw();
      }
    }
  }
  language(lang: string): void {
    if (lang === 'gr') {
      this.openSnackBar('Συγχαρητήρια!', 'success', 'done_outline');
    } else {
      this.openSnackBar('Completed!', 'success', 'done_outline');
    }
  }
  draw(): void {
    const width = 630 / this.cols,
      height = 630 / this.rows;

    for (let i = 0; i < this.board.length; i++) {
      for (let j = 0; j < this.board[i].length; j++) {
        const td = document.getElementById(`puzzle${this.rows * i + (j + 1)}`);
        if (td) {
          td.setAttribute(
            'style',
            `width: ${width}px; cursor:pointer; height: ${height}px; background: ${this.board[i][j].empty
              ? 'none'
              : 'url(' +
              this.imgUrl +
              ') no-repeat -' +
              this.board[i][j].position.col * width +
              'px -' +
              this.board[i][j].position.row * height +
              'px'
            }`
          );
        }
      }
    }
  }
  isCompleted(): boolean {
    let completed = true;
    this.islost = true;
    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.cols; col++) {
        if (
          this.board[row][col].position.row !== row ||
          this.board[row][col].position.col !== col
        ) {
          completed = false;
          this.islost = false;
        }
      }
    }

    return completed;
  }
  shuffle(): void {
    let solvable = false;
    let temp: ITile;
    let emptyTile: IPoint = { row: this.rows - 1, col: this.cols - 1 };

    // Generate a solvable board
    while (!solvable) {
      // Initialize board
      for (let i = 0; i < this.rows; i++) {
        this.board[i] = [];
        for (let j = 0; j < this.cols; j++) {
          this.board[i][j] = {
            position: {
              row: i,
              col: j,
            },
            empty: false,
          };
        }
      }
      this.board[this.rows - 1][this.cols - 1].empty = true;

      // Shuffle board
      for (let i = this.rows - 1; i >= 0; i--) {
        for (let j = this.cols - 1; j >= 0; j--) {
          if (i == emptyTile.row && j == emptyTile.col) {
            continue;
          }
          const randRow = Math.floor(Math.random() * (i + 1));
          const randCol = Math.floor(Math.random() * (j + 1));
          temp = this.board[i][j];
          this.board[i][j] = this.board[randRow][randCol];
          this.board[randRow][randCol] = temp;
        }
      }
      // Check if board is solvable
      if (this.board.length == 3) {

        solvable = this.isSolvableSmallBoard(this.board)
      } else {
        solvable = this.isSolvableBigBoard(this.board)

      }
    }

    this.draw();
  }

  isSolvableSmallBoard(board: any[][]): boolean {
    let inversions = 0;
    const size = this.rows * this.cols;
    const arr = [];

    // Flatten the board
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.cols; j++) {
        if (!board[i][j].empty) {
          arr.push(board[i][j].position.row * this.cols + board[i][j].position.col);
        }
      }
    }

    // Count inversions
    for (let i = 0; i < size - 1; i++) {
      for (let j = i + 1; j < size; j++) {
        if (arr[i] > arr[j]) {
          inversions++;
        }
      }
    }

    // Check if puzzle is solvable
    if (this.rows % 2 == 1) {
      // Odd number of rows
      return inversions % 2 == 0;
    } else {
      // Even number of rows
      const emptyRow = this.rows - Math.floor(arr.indexOf(size - 1) / this.cols) - 1;
      return (emptyRow % 2 == 0 && inversions % 2 == 1) || (emptyRow % 2 == 1 && inversions % 2 == 0);
    }
  }

  isSolvableBigBoard(board: any): boolean {
    const n = board.length;
    const flattened = board.flat().map((cell: any) => cell.empty ? n * n - 1 : cell.position.row * n + cell.position.col);

    // Count the number of inversions
    let inversions = 0;
    for (let i = 0; i < n * n - 1; i++) {
      for (let j = i + 1; j < n * n; j++) {
        if (flattened[j] < flattened[i]) {
          inversions++;
        }
      }
    }

    // If the grid width is odd, then the puzzle is solvable if the number of inversions is even
    if (n % 2 === 1) {
      return inversions % 2 === 0;
    }

    // If the grid width is even, then the puzzle is solvable if the number of inversions plus the row of the empty cell from the bottom is even
    const emptyCellRow = board.findIndex((row: any) => row.some((cell: any) => cell.empty)) + 1;
    return (inversions + emptyCellRow) % 2 === 0;
  }
}

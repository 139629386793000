<div class="position-relative">
    <!-- <img class="d-block w-100 position-absolute fadein elementToFadeInAndOut" src="/assets/img/bg.jpg" alt="Galeeriano"> -->
    <img class="d-block w-100  " src="/assets/img/bg.jpg" alt="Galeeriano">
    <div class="dashWrapper text-center mt-5 overflow-auto">
        <img class="img-width mb-3" src="/assets/img/HOME_LOGO.png" alt="Galeeriano">
        <div [style.font-size.px]="fontSize">
            <p>{{'welcome_info' | translate}}</p>
            <p>{{'welcome_info2' | translate}}</p>
        </div>
        <!-- <p>{{'welcome_info3' | translate}}</p> -->
        <img class="mt-3" src="/assets/img/footer_img.png" alt="Galeeriano">
    </div>
</div>

<!-- Display close button of  Museum Item Dialog -->
<div [style.font-size.px]="fontSize">
    <button class="close-button" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>

    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
        <!-- Display the indicators of the Museum Items Dialog -->
        <div *ngIf="data.museumItems.length > 1">
            <a class="carousel-control-prev control-position" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next control-position" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

        <!-- Display additional -->
        <div class="carousel-inner">
            <div class="carousel-item" *ngFor="
        let museumItem of data.museumItems;
        let i = index;
        let isFirst = first
      " [ngClass]="{ active: isFirst }" data-slide="i">
                <!-- Display Selected MuseumItem Image -->
                <div *ngIf="checkImageType(museumItem.img); else coin">
                    <img src="{{ museumItem.img }}" style=" margin: 0 auto;
                object-fit: scale-down;
                width: 100%;
                height: 30vh;" />
                </div>
                <ng-template #coin>
                    <img src="{{ museumItem.img }}" style="margin-left: 23%;
              object-fit: cover; width: 56%;" />
                </ng-template>

                <div>
                    <!-- Display Selected MuseumItem Name -->
                    <p class="mt-3 text-center text-white">
                        {{ museumItem.desc_short | translate }}
                    </p>
                    <!-- Display selected MuseumItem Description -->
                    <h5 [style.font-size.px]="4+fontSize" class="pt-3 text-white text-center desc">
                        {{ museumItem.desc | translate }}
                        <br />
                        <div class="pt-2">
                            <div *ngIf="museumItem.source">
                                {{museumItem.image_source | translate}}:&nbsp;&nbsp;{{museumItem.source |translate}}
                            </div>
                            <div *ngIf="museumItem.url_source">
                                <a href="{{museumItem.url_source}}" target="_blank"> {{museumItem.image_source
                  |translate}}</a>
                            </div>
                        </div>
                    </h5>
                    <br>
                    <div *ngIf="museumItem.desc_ancient_greek" class="text-white" [ngClass]="museumItem.class">
                        <h4 [style.font-size.px]="8+fontSize" style="white-space: pre-wrap; margin-right: 60px;">
                            {{museumItem.desc_ancient_greek|translate}}</h4>
                        <h4 [style.font-size.px]="8+fontSize" style="white-space: pre-wrap;">
                            {{museumItem.desc_greek | translate}}</h4>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

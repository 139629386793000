<!-- <app-navbar></app-navbar> -->
<div class="position-relative">
    <img class="d-block w-100 " src="/assets/img/bg.jpg" alt="Galeeriano">
    <img class="number1 " src="/assets/img/rotonta.png" alt="Galeeriano" (click)="selectedNumber(1)">
    <img class="number2 " src="/assets/img/kamara.png" alt="Galeeriano" (click)="selectedNumber(2)">
    <img class="number3 " src="/assets/img/ippodromos.png" alt="Galeeriano" (click)="selectedNumber(3)">
    <img class="number4 " src="/assets/img/oktagwno.png" alt="Galeeriano" (click)="selectedNumber(4)">
    <img class="number5 " src="/assets/img/vasiliki.png" alt="Galeeriano" (click)="selectedNumber(5)">
    <img class="number6 " src="/assets/img/archaeologicalmuseum.png" alt="Galeeriano" (click)="redirect()">
    <div class="dashWrapper text-center">
        <img class="img-width mb-3 vaMiddle" src="/assets/img/btn_takeawalk_black.png" alt="Galeeriano">
        <div class="text-container">
            <h5 [style.font-size.px]="4+fontSize">{{'choose_spot' | translate}}</h5>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carusel',
  templateUrl: './carusel.component.html',
  styleUrls: ['./carusel.component.css'],
})
export class CaruselComponent implements OnInit {
  public first: number = 0;
  constructor() {}

  ngOnInit(): void {}
  increase(): void {
    this.first++;
  }
  decrease(): void {
    this.first--;
  }
}

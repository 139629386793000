import { Component, OnInit } from '@angular/core';
import { TextSizeService } from 'src/app/services/text-size-service.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  fontSize!: number
  constructor(private textTransform: TextSizeService) { }

  ngOnInit(): void {
    this.textTransform.currentFontSize.subscribe(size => this.fontSize = size)
  }

}

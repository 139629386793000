<div [style.font-size.px]="fontSize">

  <nav class="navbar navbar-expand-lg navbar-light shadow-lg p-3 rounded" style="background-color: #ffffff">
    <a class="nav-link" href="#"><img class="nav-img-logo mt-2" src="/assets/img/logo.png" alt="Galeeriano"
        (click)="setStateAsActive(0)" /></a>
    <ng-container *ngIf="screenWidth > 1350 || !isPrivacyPolicyRoute; else mobile">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav w-100">
          <div class="nav-item active border-left mr-1" [ngClass]="{ 'border-top': selected === 1 }"
            (click)="setStateAsActive(1)">
            <a class="nav-link bold-grammar" routerLink="/virtualwalk">{{ "walk_1" | translate }}
            </a>
            <div class="negative-margin-top">
              <a class="nav-link black-grammar" routerLink="/virtualwalk">{{ "walk_2" | translate }}
              </a>
            </div>
            <!-- <a class="nav-link" routerLink="/virtualwalk">{{'walk' | translate}} </a> -->
          </div>
          <li class="nav-item border-left active mr-5" [ngClass]="{ 'border-top': selected === 2 }"
            (click)="setStateAsActive(2)">
            <a class="nav-link bold-grammar" routerLink="/virtualmuseum">{{ "visit_1" | translate }}
            </a>
            <div class="negative-margin-top">
              <a class="nav-link black-grammar" routerLink="/virtualmuseum">
                {{ "visit_2" | translate }}
              </a>
            </div>
            <!-- <a class="nav-link" routerLink="/virtualmeseum" routerLinkActive="activebutton">{{'visit' | translate}}</a> -->
          </li>
          <li class="nav-item border-left active mr-4" [ngClass]="{ 'border-top': selected === 3 }"
            (click)="setStateAsActive(3)">
            <a class="nav-link bold-grammar" routerLink="/museumItems">{{ "e-museum_1" | translate }}
            </a>
            <div class="negative-margin-top">
              <a class="nav-link black-grammar" routerLink="/museumItems">{{ "e-museum_2" | translate }}
              </a>
            </div>
            <!-- <a class="nav-link" routerLink="/fbxloader" routerLinkActive="activebutton">{{'e-museum' | translate}}</a> -->
          </li>
          <li class="nav-item border-left active mr-4" [ngClass]="{ 'border-top': selected === 4 }"
            (click)="setStateAsActive(4)">
            <!-- <a class="nav-link" href="#">{{'puzzle' | translate}}</a> -->
            <a class="nav-link bold-grammar" routerLink="/puzzle">{{ "puzzle_1" | translate }}
            </a>
            <div class="negative-margin-top">
              <a class="nav-link black-grammar" routerLink="/puzzle">{{ "puzzle_2" | translate }}
              </a>
            </div>
          </li>
          <li class="nav-item pointer border-left active mr-4" [ngClass]="{ 'border-top': selected === 6 }"
            (click)="setStateAsActive(6)" (click)="toggle()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            <!-- <a class="nav-link" href="#">{{'about_app' | translate}}</a> -->
            <a class="nav-link bold-grammar">{{ "about_app_1" | translate }} </a>
            <div class="negative-margin-top wk-box">
              <a class="nav-link black-grammar">{{ "about_app_2" | translate }} </a>
              <mat-icon class="c-grey-darker transY9px" [@rotatedState]="state">keyboard_arrow_down</mat-icon>
            </div>

            <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
              [cdkConnectedOverlayBackdropClass]="
            'cdk-overlay-transparent-backdrop'
          " [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="toggleDropdown()">
              <ul class="example-list">
                <li class="border-top-childrens">
                  <a class="nav-link hov" routerLink="/kinopraxia">{{ "about_app_1_1" | translate }}
                  </a>
                </li>
                <li class="border-top-childrens">
                  <a class="nav-link hov" routerLink="/domh">{{ "about_app_1_2" | translate }}
                  </a>
                </li>
                <li class="border-top-childrens">
                  <a class="nav-link hov" routerLink="/plaisio">{{ "about_app_1_3" | translate }}
                  </a>
                </li>
                <li class="border-top-childrens">
                  <a class="nav-link hov" routerLink="/deliverables">{{ "about_app_1_4" | translate }}
                  </a>
                </li>
              </ul>
            </ng-template>
          </li>
          <li class="nav-item border-left active mr-4" [ngClass]="{ 'border-top': selected === 7 }"
            (click)="setStateAsActive(7)">
            <a class="nav-link bold-grammar" routerLink="/news">{{
              "news" | translate
              }}</a>
          </li>
          <li class="nav-item border-left active mr-4 pointer " [ngClass]="{ 'border-top': selected === 8 }">
            <a class="nav-link bold-grammar" routerLink="/privacy-policy">{{
              "privacy" | translate
              }}</a>
            <div class="negative-margin-top">
              <a class="nav-link black-grammar">{{ "terms" | translate }}
              </a>
            </div>
          </li>
          <div class="d-inline-flex no-gutters language-margin pointer  ml-auto">
            <img src="assets/img/flags/gr.svg" class="img-heigth" (click)="useLanguage('gr')" />
            <p class="language-style ml-1 mr-1">|</p>
            <img src="assets/img/flags/gb.svg" class="img-heigth" (click)="useLanguage('en')" />
          </div>

        </ul>
      </div>
    </ng-container>
  </nav>
  <ng-template #mobile>
    <div class="d-inline-flex no-gutters language-margin pointer  ml-auto">
      <img src="assets/img/flags/gr.svg" class="img-heigth" (click)="useLanguage('gr')" />
      <p class="language-style ml-1 mr-1">|</p>
      <img src="assets/img/flags/gb.svg" class="img-heigth" (click)="useLanguage('en')" />
    </div>
  </ng-template>
</div>
import { Component, OnInit } from '@angular/core';
import { TextSizeService } from 'src/app/services/text-size-service.service';

@Component({
  selector: 'app-domh',
  templateUrl: './domh.component.html',
  styleUrls: ['./domh.component.css']
})
export class DomhComponent implements OnInit {
  fontSize!: number
  constructor(private textService: TextSizeService) { }

  ngOnInit(): void {
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }

}

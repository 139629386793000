<div [style.font-size.px]="fontSize">
    <!-- Display close button of  Museum Item Dialog -->
    <button class="close-button" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
    <button class="question-button" title="{{ 'threeD_object_info' | translate }}">
    <mat-icon>question_mark</mat-icon>
  </button>
    <model-viewer src="assets\models\digital_museum_{{ data.itemModel }}.gltf" max-camera-orbit="auto 90deg auto" camera-controls class="modelviewer">
        <div id="lazy-load-poster" slot="poster"></div>
    </model-viewer>
</div>

import { Component, OnInit, ViewChild, ElementRef,AfterViewInit,Renderer2 } from '@angular/core';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
//var OrbitControls = require('three-orbit-controls')(THREE);

var loader = new FBXLoader();
//import {OrbitControls} from 'three-orbit-controls';
import Scene = THREE.Scene;
import Mesh = THREE.Mesh;
import PerspectiveCamera = THREE.PerspectiveCamera;
import WebGLRenderer = THREE.WebGLRenderer;
//import TrackballControls = THREE.TrackballControls;

@Component({
  selector: 'app-fbxloader',
  templateUrl: './fbxloader.component.html',
  styleUrls: ['./fbxloader.component.css']
})

export class FbxloaderComponent implements AfterViewInit,OnInit {
  @ViewChild("myimg") myCanvas: any;
  private path:any;
  private scene: any;
  private camera: any;
  private renderer: any;
  private controls: any;
  constructor(private render: Renderer2) {
   
  
    
    

   }
   ngAfterViewInit() {
    this.init3D();
}


  ngOnInit(): void {
      //add listener for the resize of the window - will resize the renderer to fit the window
      let global = this.render.listen('window', 'resize', (evt) => {
        this.onWindowResize();
      })
  }
  init3D(){
    // renderer
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.myCanvas.nativeElement.appendChild(this.renderer.domElement);

    // scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( 0xFFFFFF );

    // camera
    this.camera = new THREE.PerspectiveCamera( 35, window.innerWidth / window.innerHeight, 0.01, 10000 );
    this.camera.position.set( 113, 111, 113 );
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.scene.add( new THREE.AmbientLight( 0x222222 ) );
    this.scene.add( this.camera ); // required, because we are adding a light as a child of the camera

    // controls
    
    this.controls = new OrbitControls(this.camera,this.renderer.domElement);

    // lights
    var light = new THREE.PointLight( 0xffffff, 0.8 );
    this.camera.add( light );

    loader.load("./assets/fbx/Rotonda/ROTUNDA.fbx", (geometry) => {
      var material = new THREE.MeshPhongMaterial( { color: 0xBEBEBE } );

     // var mesh = new THREE.Mesh( geometry, material );
      this.scene.add(geometry)
    })

    //request animation
    this.animate();

  }

  /**
   * render the scene and request the window animation frame
   */
  animate() {

    this.camera.lookAt( this.scene.position );

    this.renderer.render(this.scene, this.camera);

    window.requestAnimationFrame(_ => this.animate());

  }

  /**
   * will resize the renderer and the camera to the right size of the window
   */
  onWindowResize() {

    this.camera.aspect = window.innerWidth / window.innerHeight;

    this.camera.updateProjectionMatrix();

    this.renderer.setSize( window.innerWidth, window.innerHeight );

  }

}

import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaincontainerComponent } from './containers/maincontainer/maincontainer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './componenets/navbar/navbar.component';
import { CarouselComponent } from './componenets/virtual-museum-carusel/carousel.component';
import { FbxloaderComponent } from './componenets/fbxloader/fbxloader.component';
import { PagenotfoundComponent } from './componenets/pagenotfound/pagenotfound.component';
import { DashboardComponent } from './componenets/dashboard/dashboard.component';
import { VirtualwalkComponent } from './containers/virtualwalk/virtualwalk.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FbxdialogComponent } from './componenets/modals/fbxdialog/fbxdialog.component';
import { MatIconModule } from '@angular/material/icon';
import { ImagedialogComponent } from './componenets/modals/imagedialog/imagedialog.component';
import { VirtualmuseumComponent } from './containers/virtualmuseum/virtualmuseum.component';
import { MuseumItemComponentComponent } from './componenets/museum-item-component/museum-item-component.component';
import { MuseumItemsComponent } from './containers/museum-items/museum-items.component';
import { PuzzleComponent } from './componenets/puzzle/puzzle.component';
import { PuzzlecontainerComponent } from './containers/puzzlecontainer/puzzlecontainer.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { SnackbarmessageComponent } from './componenets/modals/snackbarmessage/snackbarmessage.component';
import { MessageComponent } from './componenets/message/message.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TimerComponent } from './componenets/timer/timer.component';
import { MobiledviewComponent } from './componenets/mobiledview/mobiledview.component';
import { CaruselComponent } from './componenets/museum-items-carousel/carusel.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DomhComponent } from './componenets/domh/domh.component';
import { KinopraxiaComponent } from './componenets/kinopraxia/kinopraxia.component';
import { PlaisioComponent } from './componenets/plaisio/plaisio.component';
import { FooterComponent } from './componenets/footer/footer.component';
import { WinImgComponent } from './componenets/modals/win-img/win-img.component';
import { MuseumDetailedDialogComponent } from './src/app/componenets/modals/museum-detailed-dialog/museum-detailed-dialog.component';
import { VirtualMuseumItemDialog } from './src/app/componenets/modals/virtual-museum-item-dialog/virtual-museum-item-dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ThreeDdialogComponent } from './componenets/modals/three-ddialog/three-ddialog.component';
import { NewsPagesComponent } from './componenets/news-pages/news-pages.component';
import { ParadoteaComponent } from './paradotea/paradotea.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { PrivacyPolicyComponent } from './componenets/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    MaincontainerComponent,
    NavbarComponent,
    CarouselComponent,
    FbxloaderComponent,
    PagenotfoundComponent,
    DashboardComponent,
    VirtualwalkComponent,
    FbxdialogComponent,
    ImagedialogComponent,
    VirtualmuseumComponent,
    MuseumItemComponentComponent,
    MuseumItemsComponent,
    PuzzleComponent,
    PuzzlecontainerComponent,
    SnackbarmessageComponent,
    MessageComponent,
    TimerComponent,
    MobiledviewComponent,
    CaruselComponent,
    DomhComponent,
    KinopraxiaComponent,
    PlaisioComponent,
    FooterComponent,
    WinImgComponent,
    VirtualMuseumItemDialog,
    MuseumDetailedDialogComponent,
    ThreeDdialogComponent,
    NewsPagesComponent,
    ParadoteaComponent,
    NewsPageComponent,
    TruncatePipe,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatSelectModule,
    OverlayModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  entryComponents: [],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material//snack-bar';
import { TextSizeService } from 'src/app/services/text-size-service.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  fontSize!: number

  public message: string = '';
  public icon: string = '';
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private textService: TextSizeService) {


    this.message = data.message;
    this.icon = data.icon;
  }

  ngOnInit(): void {
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }

}

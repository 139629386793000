<!-- <label>{{'info' | translate}}</label> -->



<ng-template #showDesktop [ngStyle]="{ 'overflow-': 'hidden' }">
  <div>
    <div class="position">
      <div role="button" class="accessbtn" mat-icon-button [matMenuTriggerFor]="menu">
        <i class="material-icons">accessibility_new</i>
      </div>

      <mat-menu [overlapTrigger]="false" [hasBackdrop]="false" #menu="matMenu" (click)="$event.stopPropagation()">
        <button class="d-flex align-items-center" mat-menu-item (click)="increaseText()">
          <i class=" material-icons ">text_increase</i>
          <span class=" px-2 ">Increase Text</span>
        </button>
        <button class=" d-flex align-items-center " mat-menu-item (click)=" decreaseText() ">
          <i class=" material-icons ">text_decrease</i>
          <span class=" px-2 ">Decrease text</span>
        </button>
        <button class=" d-flex align-items-center " mat-menu-item (click)=" grayscale=! grayscale ">
          <i class=" material-icons ">gradient</i>
          <span class=" px-2 ">Toggle grayscale</span>
        </button>
        <button class=" d-flex align-items-center " mat-menu-item (click)=" resetText() ">
          <i class=" material-icons ">settings_backup_restore</i>
          <span class=" px-2 ">Reset</span>
        </button>
      </mat-menu>
    </div>
    <div [ngClass]=" { 'grayscale' : grayscale} ">
      <app-navbar></app-navbar>
      <router-outlet></router-outlet>
      <app-footer [style.font-size.px]=" fontSize "></app-footer>
    </div>


  </div>
</ng-template>
<ng-container *ngIf="screenWidth <= 1350; else showDesktop">
  <ng-container *ngIf="isPrivacyPolicyRoute; else showMobile">
    <app-navbar></app-navbar>
    <app-privacy-policy></app-privacy-policy>
  </ng-container>

  <ng-template #showMobile>
    <app-mobiledview></app-mobiledview>
  </ng-template>
</ng-container>
<div [style.font-size.px]="fontSize">

    <div class="w-100 img-width mb-5">
        <!-- <img class="img-width mb-3" src="/assets/img/HOME_LOGO.png" alt="Galeeriano"> -->
        <div class="text-wrapper">
            <h2 [style.font-size.px]="2*fontSize">{{ "news" | translate }}</h2>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center mb-5">
            <img class="mt-3" src="/assets/img/footer_img.png" alt="Galeeriano" />
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue" (click)="goToNewsPage(6)">
                {{"news_title_7"|translate}}
            </h3>
            <div class="d-flex  flex-column">

                <p>
                    {{("news_text_7"|translate)|truncate:100:'....'}}
                </p>
                <div class="d-flex justify-content-center" style="cursor: pointer;">
                    <div class="new" (click)="goToNewsPage(6)" style="color: #464646">
                        Continue Reading
                    </div>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue" (click)="goToNewsPage(5)">
                {{"news_title_6"|translate}}
            </h3>
            <div class="d-flex  flex-column">

                <p>
                    {{("news_text_6"|translate)|truncate:100:'....'}}
                </p>
                <div class="d-flex justify-content-center" style="cursor: pointer;">
                    <div class="new" (click)="goToNewsPage(5)" style="color: #464646">
                        Continue Reading
                    </div>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue" (click)="goToNewsPage(4)">
                {{"news_title_5"|translate}}
            </h3>
            <div class="d-flex  flex-column">

                <p>
                    {{("news_text_5"|translate)|truncate:100:'....'}}
                </p>
                <div class="d-flex justify-content-center">
                    <div class="new" (click)="goToNewsPage(4)" style="color: #464646">
                        Continue Reading
                    </div>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue" (click)="goToNewsPage(3)">
                {{"news_title_4"|translate}}
            </h3>
            <div class="d-flex flex-column">
                <p>
                    {{("news_text_4"|translate)|truncate:120:'....'}}
                </p>
                <div class="d-flex justify-content-center">
                    <div class="new" (click)="goToNewsPage(3)" style="color: #464646">
                        Continue Reading
                    </div>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue" (click)="goToNewsPage(2)">
                {{"news_title_3"|translate}}
            </h3>
            <div class="d-flex  flex-column">

                <p>
                    {{("news_text_3"|translate)|truncate:100:'....'}}
                </p>
                <div class="d-flex justify-content-center">
                    <div class="new" (click)="goToNewsPage(2)" style="color: #464646">
                        Continue Reading
                    </div>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>

        <div class="row justify-content-center text-center mb-3">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue" (click)="goToNewsPage(1)">
                {{"news_title_2"|translate}}
            </h3>
            <div class="d-flex  flex-column">

                <p>
                    {{("news_text_2"|translate)|truncate:100:'....'}}
                </p>
                <div class="d-flex justify-content-center">
                    <div class="new" (click)="goToNewsPage(1)" style="color: #464646">
                        Continue Reading
                    </div>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mb-3">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue" (click)="goToNewsPage(0)">
                {{"news_title_1"|translate}}
            </h3>
            <div class="d-flex  flex-column">

                <p>
                    {{("news_text_1"|translate)|truncate:100:'....'}}
                </p>
                <div class="d-flex justify-content-center">
                    <div class="new" (click)="goToNewsPage(0)" style="color: #464646">
                        Continue Reading
                    </div>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>

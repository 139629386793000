<div [style.font-size.px]="fontSize">
    <div class="w-100 img-width mb-5">
        <!-- <img class="img-width mb-3" src="/assets/img/HOME_LOGO.png" alt="Galeeriano"> -->
        <div class="text-wrapper">
            <h2 [style.font-size.px]="2*fontSize"> {{ "about_app_1_2" | translate }}</h2>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center mb-5">
            <img class="mt-3" src="/assets/img/footer_img.png" alt="Galeeriano" />
        </div>
        <div class="row justify-content-center text-center mb-3">
            <p>
                {{ "domh_info" | translate }}
            </p>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE1" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">1</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE1_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_AMT" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE2" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">2</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE2_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_Dipae" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE3" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">3</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE3_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_Dipae" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE4" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">4</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE4_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_AMT" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE5" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">5</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE5_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_EKETA" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE6" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">6</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE6_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_EFAPOTH" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE7" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">7</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE7_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_ARX" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE8" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">8</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE8_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_EKETA" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row justify-content-center mb-5">
            <h3 [style.font-size.px]="12+fontSize" class="color-lightblue mb-3">
                {{ "domh_EE9" | translate }}
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">AA</th>
                        <th scope="col">9</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "domh_row2" | translate }}</th>
                        <td>
                            {{ "domh_EE9_row" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "domh_row3" | translate }}</th>
                        <td>{{ "domh_BIE" | translate }}</td>
                        <td>{{ "domh_foreas" | translate }}</td>
                        <td>{{ "domh_ARX" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TextSizeService } from 'src/app/services/text-size-service.service';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('false', style({ transform: 'rotate(0)' })),
      state('true', style({ transform: 'rotate(-180deg)' })),
      transition('true => default', animate('400ms ease-out')),
      transition('false => rotated', animate('400ms ease-in'))
    ])]
})
export class NavbarComponent implements OnInit {
  screenWidth: number = window.innerWidth;
  isPrivacyPolicyRoute: boolean = false;
  public selected: Number = 0;
  isOpen = false;
  state = '';
  fontSize!: number
  constructor(private translate: TranslateService, private textSizeService: TextSizeService, private router: Router, private activatedRoute: ActivatedRoute) { }
  ngOnInit(): void {
    this.textSizeService.currentFontSize.subscribe(size => this.fontSize = size)
    window.onresize = () => this.screenWidth = window.innerWidth;
    this.checkIfPrivacyPolicy();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkIfPrivacyPolicy();
    });
  }

  checkIfPrivacyPolicy(): void {
    let currentRoute = this.activatedRoute.root;
    while (currentRoute.children.length > 0) {
      currentRoute = currentRoute.children[0];
    }

    this.isPrivacyPolicyRoute = currentRoute.snapshot.routeConfig?.path === 'privacy-policy';
  }
  setStateAsActive(number: number): void {
    this.selected = number;
  }
  useLanguage(language: string): void {
    this.translate.use(language);
  }
  toggleDropdown(): void {
    this.isOpen = false;
    this.state = (this.isOpen.toString() === 'true' ? 'true' : 'false');


  }
  toggle(): void {
    this.isOpen = !this.isOpen;
    this.state = (this.isOpen.toString() === 'true' ? 'true' : 'false');
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextSizeService } from 'src/app/services/text-size-service.service';
import { VirtualMuseumItemDialog } from 'src/app/src/app/componenets/modals/virtual-museum-item-dialog/virtual-museum-item-dialog';
@Component({
  selector: 'app-virtualwalk',
  templateUrl: './virtualwalk.component.html',
  styleUrls: ['./virtualwalk.component.css'],
})
export class VirtualwalkComponent implements OnInit {
  public selected: number = 0;
  public isOpendialog: Boolean = false;
  public virtualWalkData: any;
  fontSize!: number

  constructor(public dialog: MatDialog, private textService: TextSizeService) { }

  ngOnInit(): void {
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }

  // Select Virtual Walk Item
  selectedNumber(number: number): void {
    this.selected = number;
    // Open Virual Walk Item Dialog
    this.openVirtualWalkDialog(number);
  }

  openVirtualWalkDialog(num: number) {
    this.isOpendialog = true;
    let dialog = this.dialog.open(VirtualMuseumItemDialog, {
      data: {
        name: `virtual-walk/${num}.json`,
        selected: num,
      },
      panelClass: 'myapp-no-padding-dialog',
    });
    dialog.afterClosed().subscribe((result) => {
      this.isOpendialog = false;
    });
  }

  // Go to museum original page
  redirect(): void {
    // window.open("google.com");
    window.location.replace('https://www.amth.gr/');
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-puzzlecontainer',
  templateUrl: './puzzlecontainer.component.html',
  styleUrls: ['./puzzlecontainer.component.css'],
})
export class PuzzlecontainerComponent implements OnInit {
  public allPuzzleItems: any = [];
  public dataAvailable: boolean = false;

  constructor(private httpclient: HttpClient) {}

  ngOnInit(): void {
    //Fetch puzzle images form assets
    this.fetchData();
  }

  fetchData() {
    this.httpclient
      .get('assets/puzzle/puzzle_images.json', { responseType: 'json' })
      .subscribe((data: any) => {
        this.allPuzzleItems = data;
        this.dataAvailable = true;
      });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextSizeService {
  private fontSize = new BehaviorSubject<number>(16);
  currentFontSize = this.fontSize.asObservable();
  constructor() { }

  updateFontSize(size: number) {
    this.fontSize.next(size)
  }

}

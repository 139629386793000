import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TextSizeService } from 'src/app/services/text-size-service.service';

@Component({
  selector: 'app-museum-detailed-dialog',
  templateUrl: './museum-detailed-dialog.component.html',
  styleUrls: ['./museum-detailed-dialog.component.css'],
})
export class MuseumDetailedDialogComponent implements OnInit {
  fontSize!: number
  // initialize active  MuseumItem Dialog
  // public active: number = this.data.i;
  //open 3d model

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private textService: TextSizeService
  ) { }

  // check if the image is a coin and enable prorper class
  checkImageType(img: string): boolean {
    if (img == '/assets/img/museumItems/digitalMuseum_10.jpg' || img == '/assets/img/museumItems/digitalMuseum_7.jpg' || img == '/assets/img/museumItems/digitalMuseum_11.jpg') {
      return false
    } else {
      return true
    }
  }

  ngOnInit(): void {
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }
}

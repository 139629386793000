import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VirtualMuseumItemDialog } from 'src/app/src/app/componenets/modals/virtual-museum-item-dialog/virtual-museum-item-dialog';

@Component({
  selector: 'app-virtualmuseum',
  templateUrl: './virtualmuseum.component.html',
  styleUrls: ['./virtualmuseum.component.css'],
})
export class VirtualmuseumComponent implements OnInit {
  public selected: Number = 0;
  public isOpendialog: Boolean = false;
  constructor(public dialog: MatDialog, private httpClient: HttpClient) {}
  public MuseumData: any;

  ngOnInit(): void {}

  // Select Virtual Museum Item
  selectedNumber(number: number): void {
    this.selected = number;
    // Open Virtual Museum Item Dialog
    this.openVirtualMuseumDialog(number);
  }

  //  Virutal Museum Item Dialog
  openVirtualMuseumDialog(num: number) {
    this.isOpendialog = true;
    let dialog = this.dialog.open(VirtualMuseumItemDialog, {
      data: {
        name: `/museum_Items/${num}.json`,
        selected: num,
      },
      panelClass: 'myapp-no-padding-dialog',
    });
    dialog.afterClosed().subscribe((result) => {
      this.isOpendialog = false;
    });
  }
}

<div [style.font-size.px]="fontSize">
    <div class="img-width mb-5">
        <!-- <img class="img-width mb-3" src="/assets/img/HOME_LOGO.png" alt="Galeeriano"> -->
        <div class="text-wrapper">
            <h2 [style.font-size.px]="2*fontSize"> {{"privacy"|translate}} {{"terms"|translate}}
            </h2>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center text-center mb-3">
            <p>
                {{"privacyPolicy_info"|translate}}
            </p>
            <ul class="mb-4">
                <li class="pb-3">
                    {{"privacyPolicy_point_1"|translate}}
                </li>
                <li class="pb-3">
                    {{"privacyPolicy_point_2"|translate}}
                </li>
                <li class="pb-3">
                    {{"privacyPolicy_point_3"|translate}}
                </li>

                <li class="pb-3">
                    {{"privacyPolicy_point_4"|translate}}
                </li>
                <li class="pb-3">
                    {{"privacyPolicy_point_5"|translate}}
                </li>
                <li class="pb-3">
                    {{"privacyPolicy_point_6"|translate}}
                </li>
            </ul>
        </div>
        <div class="row justify-content-center mb-2">
            <p class="font-weight-bold">
                {{"privacyPolicy_title"|translate}}
            </p>
        </div>
        <div class="row justify-content-center">
            <p>
                {{"privacyPolicy_footer"|translate}}
            </p>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MuseumDetailedDialogComponent } from 'src/app/src/app/componenets/modals/museum-detailed-dialog/museum-detailed-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ThreeDdialogComponent } from '../modals/three-ddialog/three-ddialog.component';
import { TextSizeService } from 'src/app/services/text-size-service.service';

@Component({
  selector: 'app-museum-item-component',
  templateUrl: './museum-item-component.component.html',
  styleUrls: ['./museum-item-component.component.css'],
})
export class MuseumItemComponentComponent implements OnInit {
  allMuseumItems: any;
  @Input() start!: number;
  @Input() stop!: number;
  fontSize!: number

  public isOpenDialog: boolean = false;
  constructor(private httpclient: HttpClient, private dialog: MatDialog,
    private textService: TextSizeService) { }

  //fetch MuseumItemData
  ngOnInit(): void {
    this.httpclient
      .get(`assets/dataItemmuseum.json`, { responseType: 'json' })
      .subscribe((data: any) => {
        this.allMuseumItems = data;
      });
    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }

  //Pass to dialog: the museumItemData, and the index of selected museumItem
  openDialog(museumItems: any) {
    this.isOpenDialog = true;
    let dialog = this.dialog.open(MuseumDetailedDialogComponent, {
      data: { museumItems },

      //  Styles for full screen dialog
      // maxHeight: '100vh',
      // maxWidth: '100vw',
      // height: '100%',
      // width: '100%',

      panelClass: 'museum-item-dialog',
    });
    dialog.afterClosed().subscribe((result) => {
      this.isOpenDialog = false;
    });
  }

  // check if the image is a coin and enable prorper class


  open3DDialog(itemModel: any) {
    this.isOpenDialog = true;
    let dialog = this.dialog.open(ThreeDdialogComponent, {
      data: { itemModel },

      panelClass: 'museum-item-dialog-3d',
    });
    dialog.afterClosed().subscribe((result) => {
      this.isOpenDialog = false;
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImagedialogComponent } from '../modals/imagedialog/imagedialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TextSizeService } from 'src/app/services/text-size-service.service';
@Component({
  host: { class: 'w-100' },
  selector: 'app-carousel-for-virtualMuseumItems',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})
export class CarouselComponent implements OnInit {
  public url: any;
  public iconPosition: any
  fontSize!: number
  public active: any = 0;
  @Input() details: any = [];
  constructor(public dialog: MatDialog, private textService: TextSizeService) { }

  ngOnInit(): void {
    this.textService.currentFontSize.subscribe(size => this.fontSize = size)
  }

  //Zoom image of Carousel
  zoomImage(iconPosition: number) {
    let activeEle = document.querySelector('.carousel-item.active img');
    if (activeEle != null) {
      this.iconPosition = iconPosition
      this.url = activeEle.getAttribute('src');
      this.openDialog();
    }
  }

  openDialog() {
    this.dialog.open(ImagedialogComponent, {
      data: {
        url: this.url,
        details: this.details,
        iconPosition: this.iconPosition
      },
      panelClass: 'image-dialog-presentation',
    });
  }
}

<!-- Display VirtualWalk Items Descipriton -->
<div class="row mt-3 mb-4 ml-2 back-description custom-pidding-top" *ngIf="details[0]?.desci">
    <div class="col-auto mr-3 mt-3">
        <img class="vaMiddle img-width-info" style="transform: scale(0.7)" src="/assets/img/info_icon.png" alt="Galeeriano" />
    </div>
    <div class="col paragraph">
        <!-- Mini subtitle -->
        <h6 class="text-center" [style.font-size.px]="fontSize">{{details[0]?.desc_short | translate}}</h6>
        <p [innerHtml]="details[0]?.desci | translate"></p>
    </div>
</div>

<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
    <!-- Display selected VirtualMuseum/VirtualWalk Item Image -->
    <div class="carousel-inner pt-2">
        <div class="carousel-item" *ngFor="let detail of details; let i = index" [ngClass]="{ active: active === i }">
            <div class="image">
                <img src="{{ detail.img }}" alt="Galeeriano" class="carousel-test" (click)="zoomImage(i)" />
                <!-- Zoom selected Virtual Museum Item image -->
                <div>
                    <h6 *ngIf="detail.img_label" [style.font-size.px]="fontSize" class="text-center pt-3 image-label">
                        {{detail.img_label|translate}}</h6>
                    <i class="zoom" (click)="zoomImage(i)">
            <mat-icon class="icon-display border-radius-zoom">zoom_in</mat-icon>
          </i>
                </div>
            </div>
            <div class="info-class back-description" *ngIf="detail.desc">
                <div class="col-auto">
                    <img class="vaMiddle img-width-info img-style" src="/assets/img/info_icon.png" alt="Galeeriano" />
                </div>
                <!-- Display selected Virutal Museum Item description -->
                <div class="col paragraph">
                    <!-- Mini sub title -->
                    <h6 class="text-center" [style.font-size.px]="fontSize">{{detail.desc_short | translate}}</h6>
                    <p [innerHtml]="detail.desc | translate"></p>
                    <div *ngIf="detail.source" class="image-source">
                        <p>{{detail.image_source | translate}}:&nbsp;&nbsp;{{detail.source | translate}}</p>
                    </div>
                    <div *ngIf="detail.url_source" class="image-source d-flex">
                        <a href="{{detail.url_source}}" target="_blank" class="px-2 my-3">{{detail.image_source | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Display Carousel Indicators if  there is more than 1 image-->
    <div *ngIf="details.length > 1">
        <a class="carousel-control-prev center-arrow" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next center-arrow" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>
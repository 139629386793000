import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  url: any;
  details: any;
  iconPosition: any;
}
@Component({
  selector: 'app-imagedialog',
  templateUrl: './imagedialog.component.html',
  styleUrls: ['./imagedialog.component.css'],
})
export class ImagedialogComponent implements OnInit {
  public url: any;
  public imagesArray: any
  public iconPosition: any
  public imageUrl: any
  imageLabel: any
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.url = this.data.url;
    this.imagesArray = this.data.details
    this.iconPosition = this.data.iconPosition
    this.imageUrl = this.imagesArray[this.iconPosition].img
    this.imageLabel = this.imagesArray[this.iconPosition].img_label
  }

  decrease(): void {
    if (this.iconPosition == 0) {
      this.iconPosition = this.imagesArray.length - 1
    } else {
      this.iconPosition--
    }
    this.imageUrl = this.imagesArray[this.iconPosition].img
    this.imageLabel = this.imagesArray[this.iconPosition].img_label
  }

  increase(): void {
    if (this.iconPosition == this.imagesArray.length - 1) {
      this.iconPosition = 0;
    }
    else {
      this.iconPosition++;
    }
    this.imageUrl = this.imagesArray[this.iconPosition].img
    this.imageLabel = this.imagesArray[this.iconPosition].img_label
  }
}



<div class="position-relative w-100 h-100vh oy-hidden">
  <div class="position-absolute w-100 h-100">
    <img
      class="position-absolute w-100 h-100"
      src="/assets/img/bg.jpg"
      alt="Galeeriano"
    />
    <div
      class="position-absolute w-100 h-100 opacity-07 light-black-color top-0"
    ></div>

    <!-- Pass Puzzle images to app puzle component -->
    <app-puzzle
      *ngIf="dataAvailable"
      [puzzleImages]="allPuzzleItems"
    ></app-puzzle>
  </div>
</div>

<div [style.font-size.px]="fontSize">
    <div class="img-width mb-5">
        <!-- <img class="img-width mb-3" src="/assets/img/HOME_LOGO.png" alt="Galeeriano"> -->
        <div class="text-wrapper">
            <h2 [style.font-size.px]="2*fontSize">{{ "about_app_1_3" | translate }}</h2>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center mb-5">
            <img class="mt-3" src="/assets/img/footer_img.png" alt="Galeeriano" />
        </div>
        <div class="row justify-content-center text-center mb-3">
            <p>
                {{"plaisio_intro"|translate}}
            </p>
            <ul class="mb-4">
                <li>
                    {{"plaisio_goal_1"|translate}}
                </li>
                <li>
                    {{"plaisio_goal_2"|translate}}
                </li>
                <li>
                    {{"plaisio_goal_3"|translate}}
                </li>
            </ul>
        </div>
        <div class="row justify-content-center mb-5">
            <p>
                {{"plaisio_bottom_1"|translate}}
            </p>
        </div>
        <div class="row justify-content-center mb-5">
            {{"plaisio_bottom_2"|translate}}
        </div>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VirtualmuseumComponent } from 'src/app/containers/virtualmuseum/virtualmuseum.component';
import { TextSizeService } from 'src/app/services/text-size-service.service';

@Component({
  selector: 'app-virtual-museum-item-dialog-modal',
  templateUrl: './virtual-museum-item-dialog.component.html',
  styleUrls: ['./virtual-museum-item-dialog.css'],
})
export class VirtualMuseumItemDialog implements OnInit {
  fontSize!: number
  public allMuseumItems: any = [];
  public selectedItemData: any = [];
  public selectedItemName: any;
  public selectedItemDataLocation: string = ''
  public selectedItem: any;
  public url1: string = '';
  virtualwalkpage: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<VirtualmuseumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public httpclient: HttpClient,
    public router: Router,
    private textService: TextSizeService
  ) { }

  ngOnInit(): void {
    this.url1 = this.router.url;
    if (this.url1.includes('virtualwalk')) {
      this.virtualwalkpage = true;
    }
    this.selectedItem = this.data.selected;
    this.fetchData(this.data.name, this.selectedItem);

    this.textService.currentFontSize.subscribe(size => {
      this.fontSize = size;
    });
  }

  //Fetch Virtual Museum Item Data
  fetchData(category: string, selected: number) {
    this.httpclient
      .get(`assets/${category}`, { responseType: 'json' })
      .subscribe((data: any) => {
        this.allMuseumItems = data;
        this.selectedItemData = data[selected - 1].categoryData;
        this.selectedItemName = data[selected - 1].categoryName;
        this.selectedItemDataLocation = data[selected - 1].location
      });

  }

  //Change Virtual Museum Item Data
  changeData(number: number) {
    this.selectedItemData = this.allMuseumItems[number - 1].categoryData;
    this.selectedItemName = this.allMuseumItems[number - 1].categoryName;
    this.selectedItemDataLocation = this.allMuseumItems[number - 1].location
    this.selectedItem = number;
  }

  open3DModel() {
    //this.openDialog(this.selected);
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/fbxdialog`, this.selectedItem])
    );

    window.open(url, '_blank');
  }

  //Redirect to follow Url
  redirect(url: string) {
    window.open(url, '_blank')?.focus();
  }
}
